@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&family=Rubik+Vinyl&family=Cherry+Bomb+One&family=Arimo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Sen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Oswald', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: "Lato", sans-serif;
  line-height: 2rem;

}

p {
  line-height: 2rem;
}

h1, h2, h3, li {
  font-family: 'Oswald', sans-serif;

}

hr{
  border-top: 1px solid gray;
}

span{
  all: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.drop-shadow-dark{
  filter: drop-shadow(-0px 0px 15px #09081fa2);
}
.drop-shadow-dark-2{
  filter: drop-shadow(-0px 0px 20px #09081f4a);
}

.gradient-nav{
  background: rgb(4,0,79);
background: linear-gradient(0deg, rgba(4,0,79,0) 0%, rgba(255,255,255,0.7849681032794064) 40%, rgba(255,255,255,1) 100%);
}

.bg-gradient-1{
  background-image: linear-gradient(to top, #48a4ef6c 0 3%,  #48a4ef92 3% 7%,  #48a4efcb 7% 11%, #48a4ef 11% 14%, #6f86d6 88% 91%, #6f85d6d9 91% 94%,  #6f85d6a9 94% 97%,  #6f85d653 97% 100%);
}

Link{
  font-family: 'Oswald', sans-serif;
}

@layer components
{

  .highlight {
    @apply font-semibold text-blue-500 ;
  }
  #imagepreload::after{
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    content: url(imgs/honduras2017/12.JPG) url(imgs/honduras2017/14.JPG) url(imgs/honduras2017/15.JPG) url(imgs/honduras2019/24.jpg);
    
  }
  #introimages{
    @apply bg-cover bg-center bg-no-repeat;
    background-image:  url(imgs/honduras2017/12.JPG);
    background-size: cover;
    transition: 5s, background-size none;
    animation-name: animate;
    animation-direction: alternate-reverse;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-duration: 25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-delay:  8s;
    
  }

  @keyframes animate {
    0%{
      background-image: url(imgs/honduras2017/12.JPG);
    }
    25%{
      background-image: url(imgs/honduras2017/14.JPG);
    }
    50%{
      background-image: url(imgs/honduras2017/15.JPG);
    }
    75%{
      background-image: url(imgs/honduras2019/11.jpg);
    }
    100%{
      background-image: url(imgs/honduras2017/12.JPG);
    }
  }

  
}